import React, { useState } from 'react'
import axios from 'axios'
import useInput from '../useInput'
import useSwr from 'swr'

function Groups () {
  const { data, mutate } = useSwr('/api/admin/groups')
  const [curGroup, setCurGroup] = useState()
  const { value: name, setValue: setName, bind: bindName } = useInput('')
  const { value: phone, setValue: setPhone, bind: bindPhone } = useInput('')
  const { value: tablet, setValue: setTablet, bind: bindTablet } = useInput('')
  const { value: dwl, setValue: setDwl, bind: bindDwl } = useInput('')

  const createGroup = () => {
    setName('')
    setPhone('')
    setTablet('')
    setDwl('')
    setCurGroup('new')
  }
  const editGroup = group => {
    console.log(group)
    setName(group.name)
    setPhone(group.devices.phone)
    setTablet(group.devices.tablet)
    setDwl(group.devices.dwl)
    setCurGroup(group)
  }
  const saveGroup = async () => {
    console.log(curGroup)
    const isNew = curGroup === 'new'
    try {
      await axios({
        url: `/api/admin/groups${isNew ? '' : '/' + curGroup.id}`,
        method: isNew ? 'POST' : 'PUT',
        data: {
          name,
          devices: {
            phone,
            tablet,
            dwl
          }
        }
      })
    } catch (e) {
      alert('Error')
    }
    mutate()
    setCurGroup()
  }
  const deleteGroup = async () => {
    try {
      await axios({
        url: `/api/admin/groups/${curGroup.id}`,
        method: 'DELETE'
      })
    } catch (e) {
      alert('Error')
    }
    mutate()
    setCurGroup()
  }

  return (<div className="group-list admin">
    <h2>Manage Groups</h2>
    {data && data.map((group, i) => (
      <button key={i} onClick={() => editGroup(group)}>{group.name}</button>
    ))}
    <button className="button" onClick={createGroup}>Create New</button>
    {curGroup && <div className="box-large"><div className="admin-modal">
      <h2>Edit Group</h2>
      <label>Name</label>
      <input {...bindName} />
      <label>Phone ID</label>
      <input {...bindPhone} />
      <label>Tablet ID</label>
      <input {...bindTablet} />
      <label>DWL ID</label>
      <input {...bindDwl} />
      <button onClick={saveGroup}>Save</button>
      {curGroup !== 'new' && <button onClick={deleteGroup} className="red">Delete</button>}
    </div></div>}
  </div>)
}

export default Groups
