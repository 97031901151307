import React, { useState } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'

import useInput from './useInput'

function SignIn ({ setLoggedIn, setName }) {
  const [submitting, setSubmitting] = useState(false)
  const [error, setError] = useState('')
  const { value: email, bind: bindEmail } = useInput('')
  const { value: password, bind: bindPassword } = useInput('')

  const post = async () => {
    if (submitting) return
    setSubmitting(true)
    try {
      const { data } = await axios({
        method: 'POST',
        url: '/api/user/sign-in',
        data: { email, password }
      })
      setLoggedIn(true)
      if (data.name) setName(data.name)
    } catch (e) {
      if (e.response && e.response.data && e.response.data.error) {
        setError(e.response.data.error)
      } else setError('Unknown error')
    }
    setSubmitting(false)
  }

  return <div className="sign-in">
    <h1>Sign In</h1>
    <div>
      <input type="email" id="email" placeholder="Email Address" {...bindEmail} />
    </div>
    <div>
      <input type="password" id="password" placeholder="Password" {...bindPassword} onKeyPress={event => { if (event.key === 'Enter') post() }} />
    </div>
    <div>
      <p className="error">{error}</p>
      <button onClick={post} disabled={submitting}>Sign In</button>
    </div>
  </div>
}

SignIn.propTypes = {
  setLoggedIn: PropTypes.func.isRequired,
  setName: PropTypes.func.isRequired
}

export default SignIn
