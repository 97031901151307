import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'

function GroupList () {
  const [groups, setGroups] = useState()
  useEffect(() => {
    async function run () {
      const { data } = await axios('/api/groups')
      console.log(data)
      setGroups(data)
    }
    run().catch(console.error)
  }, [])

  return <div className="group-list">
    <h2>Connect to device group</h2>
    {groups
      ? groups.sort((a, b) => a.name > b.name ? 1 : -1).map((group, i) => <Link key={i} to={`/dashboard/${group.id}`} className="button">{group.name}</Link>)
      : <p>Loading...</p>}
  </div>
}

export default GroupList
