import React, { useState } from 'react'
import axios from 'axios'
import useInput from '../useInput'
import useSwr from 'swr'

function Users () {
  const { data, mutate } = useSwr('/api/admin/users')
  const [curUser, setCurUser] = useState()
  const { value: name, setValue: setName, bind: bindName } = useInput('')
  const { value: email, setValue: setEmail, bind: bindEmail } = useInput('')
  const { value: password, setValue: setPassword, bind: bindPassword } = useInput('')
  const [isAdmin, setIsAdmin] = useState(false)

  const createUser = () => {
    setName('')
    setEmail('')
    setPassword('')
    setIsAdmin(false)
    setCurUser('new')
  }
  const editUser = user => {
    setName(user.name)
    setEmail(user.email)
    setPassword('')
    setIsAdmin(user.is_admin)
    setCurUser(user)
  }
  const saveUser = async () => {
    console.log(curUser)
    const isNew = curUser === 'new'
    try {
      await axios({
        url: `/api/admin/users${isNew ? '' : '/' + curUser.id}`,
        method: isNew ? 'POST' : 'PUT',
        data: {
          name,
          email,
          password,
          is_admin: isAdmin
        }
      })
    } catch (e) {
      alert('Error')
    }
    mutate()
    setCurUser()
  }
  const deleteUser = async () => {
    try {
      await axios({
        url: `/api/admin/users/${curUser.id}`,
        method: 'DELETE'
      })
    } catch (e) {
      alert('Error')
    }
    mutate()
    setCurUser()
  }

  return (<div className="group-list admin">
    <h2>Manage Users</h2>
    {data && data.map((user, i) => (
      <button key={i} onClick={() => editUser(user)}>{user.email} - {user.name}</button>
    ))}
    <button className="button" onClick={createUser}>Create New</button>
    {curUser && <div className="box-large"><div className="admin-modal">
      <h2>Edit Group</h2>
      <label>Name</label>
      <input {...bindName} />
      <label>Email</label>
      <input {...bindEmail} />
      <label>Password (leave blank to not change)</label>
      <input {...bindPassword} />
      <label>Is Admin?</label>
      <input type="checkbox" checked={isAdmin} onChange={e => setIsAdmin(e.target.checked)} />
      <button onClick={saveUser}>Save</button>
      {curUser !== 'new' && <button onClick={deleteUser} className="red">Delete</button>}
    </div></div>}
  </div>)
}

export default Users
