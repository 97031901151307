import React from 'react'
import io from 'socket.io-client'
import { OTPublisher, OTSubscriber, createSession } from 'opentok-react'
import find from 'lodash-es/find'
import PropTypes from 'prop-types'
import save from './assets/images/save.svg'
import { jsPDF } from 'jspdf'

// copy-pasted
function getTime () {
  const getMinutes = minutes => {
    if (parseInt(minutes) < 10) {
      minutes = '0' + minutes
    }
    return minutes
  }
  const currentTime = new Date()
  const timeOfDay = currentTime.toLocaleTimeString().substr(currentTime.toLocaleTimeString().toString().length - 2)
  return currentTime.getHours() + ':' + getMinutes(currentTime.getMinutes()) + ' ' + timeOfDay
}

const deviceNames = {
  dwl: 'DWL',
  phone: 'Headset',
  tablet: 'Tablet'
}

class Dashboard extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      streams: [],
      sessionHelper: undefined,
      largeBox: '',
      error: '',
      chatBox: '',
      chatMessages: ['Session Joined: ' + new Date()],
      audioOnly: false,
      publishingAudio: true
    }
    this.otPublisher = React.createRef()
  }

  componentDidMount () {
    this.socket = io()
    this.socket.on('startSession', this.startSession.bind(this))
    this.socket.on('chat message', msg => {
      console.log(msg)
      this.setState({
        chatMessages: [...this.state.chatMessages, `${msg.client} ${getTime()}: ${msg.message}`],
        chatBox: ''
      })
    })
    this.socket.on('becomeHost', () => {
      this.setState({
        audioOnly: false
      })
    })
    this.socket.on('requestRelinquishHost', () => {
      if (!this.state.audioOnly) {
        this.setState({
          audioOnly: true
        })
        this.socket.emit('ackRelinquishHost', true)
      }
    })
    this.socket.emit('deviceAvailable', { groupId: this.props.match.params.group }, ackData => {
      console.log(ackData)
      if (ackData.error) this.setState({ error: ackData.error })
    })
  }

  startSession (data) {
    this.setState({
      sessionHelper: createSession({
        apiKey: data.apiKey,
        sessionId: data.sessionId,
        token: data.token,
        onStreamsUpdated: streams => { this.setState({ streams }) }
      }),
      audioOnly: data.audioOnly
    })
  }

  componentWillUnmount () {
    if (this.state.sessionHelper) this.state.sessionHelper.disconnect()
    this.socket.disconnect()
  }

  getUIBox (name, component) {
    const large = this.state.largeBox === name
    const toggleLarge = () => {
      if (large) {
        this.setState({ largeBox: '' })
      } else {
        this.setState({ largeBox: name })
      }
    }

    return <>
      {large && <div className="spacer" />}
      <div className={large ? 'box-large' : ''}>
        <div className="box">
          <div className="title">
            <p>{deviceNames[name]}</p>
            <div className="button" onClick={toggleLarge}>Make {large ? 'Smaller' : 'Larger'}</div>
          </div>
          <div className="display"><div>{component}</div></div>
        </div>
      </div>
    </>
  }

  getNamedSubscriber (name) {
    const stream = find(this.state.streams, { name })
    if (stream) {
      return <OTSubscriber
        session={this.state.sessionHelper.session}
        stream={stream}
        properties={{ showControls: false }}
      />
    }
    return null
  }

  getNamedSubscriberBox (name) {
    const subscriber = this.getNamedSubscriber(name)
    if (subscriber) {
      return this.getUIBox(name, subscriber)
    }
    return this.getUIBox(name, <div className="placeholder"><p>Waiting for device...</p></div>)
  }

  sendChatMessage = () => {
    const msg = this.state.chatBox
    console.log(msg)
    console.log(this.socket)
    this.socket.emit('chat message', { client: 'Trainer', message: msg, dateTime: getTime() })
  }

  requestBecomeHost = () => {
    this.socket.emit('requestBecomeHost', true)
  }

  toggleMute = () => {
    if (this.state.publishingAudio) {
      this.setState({ publishingAudio: false })
    } else {
      this.setState({ publishingAudio: true })
    }
  }

  publisherEventHandlers = {
    streamCreated: event => {
      this.setState({ publishingAudio: event.stream.hasAudio })
    }
  }

  saveChat = () => {
    // eslint-disable-next-line new-cap
    const doc = new jsPDF()

    doc.addImage('/logo.png', 'PNG', 20, 15, 70, 12)
    doc.setFontSize(14)

    let curHeight = 40
    for (const message of this.state.chatMessages) {
      const pageHeight = doc.internal.pageSize.height
      if (15 + curHeight > pageHeight) {
        doc.addPage()
        curHeight = 25
      }
      const split = doc.splitTextToSize(message, 175)
      doc.text(split, 20, curHeight)
      curHeight += 8 * split.length
    }

    doc.save('Zeriscope Tele-Trainer Chat Log.pdf')
  }

  render () {
    return (
      <div>
        {this.state.sessionHelper ? <>
          <div className="row">
            {this.getNamedSubscriberBox('phone')}
            {this.getNamedSubscriberBox('tablet')}
          </div>
          <div className="row">
            <div>
              <div className="box">
                <div className="title">
                  <p>Chat/You</p>
                  <div className="publisher-controls">
                    {this.state.audioOnly ? <div className="button" onClick={this.requestBecomeHost}>Become Host</div> : <p>You are the host</p>}
                    <div className={`button${this.state.publishingAudio ? '' : ' red'}`} onClick={this.toggleMute}>{this.state.publishingAudio ? 'Mute' : 'Unmute'} Mic</div>
                  </div>
                </div>
                <div className="display"><div>
                  <div className="pip">
                    {this.state.audioOnly
                      ? <>
                        {this.getNamedSubscriber('desktop')}
                        <OTPublisher session={this.state.sessionHelper.session} properties={{ name: 'desktop', publishVideo: false, disableAudioProcessing: false, publishAudio: this.state.publishingAudio }} style={{ display: 'none' }} ref={this.otPublisher} eventHandlers={this.publisherEventHandlers} />
                      </>
                      : <>
                        <OTPublisher session={this.state.sessionHelper.session} properties={{ name: 'desktop', showControls: false, disableAudioProcessing: false, publishAudio: this.state.publishingAudio }} ref={this.otPublisher} eventHandlers={this.publisherEventHandlers} />
                        {this.state.streams.filter(stream => stream.name === 'desktop' && stream.hasVideo === false).map((stream, i) => <OTSubscriber
                          session={this.state.sessionHelper.session}
                          stream={stream}
                          style={{ display: 'none' }}
                          key={i}
                        />)}
                      </>}
                  </div>
                  <div className="chat">
                    <div className="chat-box"><ul>{this.state.chatMessages.map((message, i) => <li key={i}>{message}</li>)}</ul></div>
                    <div className="chat-controls">
                      <input
                        type="text"
                        value={this.state.chatBox}
                        onChange={event => this.setState({ chatBox: event.target.value })}
                        onKeyPress={event => { if (event.key === 'Enter') this.sendChatMessage() }}
                        placeholder="Type your chat message here"
                      />
                      <button onClick={this.sendChatMessage} style={{ marginRight: 8 }}>Send</button>
                      <button onClick={this.saveChat}><img src={save} alt="Save" /></button>
                    </div>
                  </div>
                </div></div>
              </div>
            </div>
            {this.getNamedSubscriberBox('dwl')}
          </div>
        </> : <div className="waiting"><h1>{this.state.error === '' ? 'Connecting...' : this.state.error}</h1></div>}
      </div>
    )
  }
}

Dashboard.propTypes = {
  match: PropTypes.object.isRequired
}

export default Dashboard
