/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from 'react-router-dom'
import axios from 'axios'

import Dashboard from './Dashboard'
import SignIn from './SignIn'
import GroupList from './GroupList'
import NotFound from './NotFound'
import Groups from './admin/Groups'
import Users from './admin/Users'

import logo from './assets/images/logo.png'

function App () {
  const [loggedIn, setLoggedIn] = useState()
  const [admin, setAdmin] = useState(false) //
  const [name, setName] = useState('')

  useEffect(() => {
    async function loginCheck () {
      try {
        const { data } = await axios({
          url: '/api/user/login-check'
        })
        setLoggedIn(true)
        if (data.name) setName(data.name)
        if (data.is_admin) setAdmin(true)
      } catch (e) {
        if (e.response && e.response.status === 401) {
          setLoggedIn(false)
        } else {
          console.error(e)
        }
      }
    }
    loginCheck()
  }, [])

  const signOut = async () => {
    try {
      await axios({
        url: '/api/user/logout'
      })
      setLoggedIn(false)
      setName('')
    } catch (e) {
      console.error(e)
    }
  }

  return <Router>
    <div className="top-bar">
      <nav>
        <Link to="/"><img src={logo} alt='Zeriscope' /></Link>
        {loggedIn === true && <div>
          {name !== '' && <p>Welcome, {name}</p>}
          {admin && <>
            <Link to="/admin/groups">Manage Groups</Link>
            <Link to="/admin/users">Manage Users</Link>
          </>}
          <a href="#" onClick={signOut}>Sign Out</a>
        </div>}
      </nav>
    </div>
    {loggedIn === undefined ? <div className="waiting">
      <h1>Loading...</h1>
    </div> : loggedIn === true ? <Switch>
      <Route path="/dashboard/:group" component={Dashboard} />
      <Route path="/" exact>
        <GroupList />
      </Route>
      {admin && <>
        <Route path="/admin/groups">
          <Groups />
        </Route>
        <Route path="/admin/users">
          <Users />
        </Route>
      </>}
      <Route>
        <NotFound />
      </Route>
    </Switch> : <SignIn setLoggedIn={setLoggedIn} setName={setName} />}
  </Router>
}

export default App
